export const helmetPageValues = {
  home: {
    title: 'InfiTeq | Home',
    href: 'http://infiteq.com',
  },
  services: {
    title: 'InfiTeq | Services',
    href: 'http://infiteq.com/services',
  },
  contact: {
    title: 'InfiTeq | Contact',
    href: 'http://infiteq.com/contact',
  },
  design: {
    title: 'InfiTeq | Design',
    href: 'http://infiteq.com/design',
  },
  websites: {
    title: 'InfiTeq | Websites',
    href: 'http://infiteq.com/websites',
  },
  marketing: {
    title: 'InfiTeq | Marketing',
    href: 'http://infiteq.com/marketing',
  },
}