import React from 'react';
import { graphql } from 'gatsby';

import { helmetPageValues as hel } from '../utils/constants';
import { Layout, ContactLayout } from '../layouts';
import { GridText, Helmet } from '../components';
import GridSubMain from '../components/shared/GridSubMain';
import GridSubOne from '../components/shared/GridSubOne';
import GridSubTwo from '../components/shared/GridSubTwo';

const paragraph = `If you are just getting out in the market or have already been there 
  for years and  just want a fresh look, our designers will make sure to provide your business 
  the look that will speak your companies core values.`;

const subtitle = `We are here to help you develop unique brand for your business.`;

const Design = ({ data: { fluid: { childImageSharp } }}) => {
  return (
    <Layout>
      <Helmet {...hel.design} />
      <ContactLayout page="" >
        <GridSubMain columns="3fr 5fr">
          <GridSubOne image={childImageSharp.fluid} />
          <GridSubTwo>
            <GridText
              isInverse
              heading="Design"
              nodes={['Design', 'Branding', 'UX/UI']}
              paragraph={paragraph}
              subtitle={subtitle}
            />
          </GridSubTwo>
        </GridSubMain>
      </ContactLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    fluid: file(relativePath: {eq: "design.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Design;
